import { get, sortBy } from 'lodash';
import { PAID_CODE, FREE_CODE } from 'site-modules/shared/components/delivery/constants';
import { LEASE_PAYMENT, LOAN_PAYMENT } from 'site-modules/shared/constants/allowed-inventory-request-params';
import { formatPriceString } from 'site-modules/shared/utils/price-utils';
import { isCpo } from 'site-modules/shared/utils/inventory-utils/is-cpo';
import { getVdpUrl } from 'site-modules/shared/utils/inventory-utils/get-vdp-url';
import { isTexas } from 'site-modules/shared/utils/inventory-utils/is-texas';
import { getDifferenceInDays } from 'site-modules/shared/utils/date-utils';
import { getCabType } from 'site-modules/shared/utils/get-cab-type';
import { isTransferDealership } from 'site-modules/shared/utils/dealer-details-utils';
import { isLeasePaymentsEligible } from 'client/site-modules/shared/utils/inventory-utils/is-lease-payments-eligible';
import { isNew as isNewType } from 'site-modules/shared/utils/inventory-utils/is-new';
import { getEngineTypeValue } from 'site-modules/shared/utils/inventory/get-engine-type-value';

const BODY_TYPES_ENHANCED = ['Hatchback', 'Coupe', 'Convertible'];

export const INVENTORY_BADGE_LABELS = {
  PRICE_DROP: 'Price Drop',
  NEW_LISTING: 'New Listing',
  ARRIVING_SOON: 'Arriving Soon',
};

export function getDeliveryText({ isNational, vehicle, isCarmaxDealer, radius, visitorStateCode }) {
  const deliveryCode = get(vehicle, 'deliveryOptions.deliveryCode');

  if (deliveryCode) {
    if (isCarmaxDealer) {
      if (isNational) {
        return 'Transfer available';
      }

      const vinDistance = vehicle?.dealerInfo?.distance;

      if (vinDistance > radius) {
        const vinStateCode = vehicle?.dealerInfo?.address?.stateCode;

        if (vinStateCode !== visitorStateCode) {
          return `Transfer available from ${vinStateCode} to ${visitorStateCode}`;
        }

        const vinCity = vehicle?.dealerInfo?.address?.city;

        return `Transfer available from ${vinCity}, ${vinStateCode}`;
      }

      return '';
    }

    if (isNational) {
      return 'Delivery available';
    }
    const parentDealershipName = get(vehicle, 'dealerInfo.displayInfo.parentDealershipName', '');
    if (deliveryCode === PAID_CODE) {
      return isTransferDealership(parentDealershipName) ? 'Delivery available' : 'Home delivery';
    }
    if (deliveryCode === FREE_CODE) {
      return isTransferDealership(parentDealershipName) ? 'Free delivery available' : 'Free home delivery';
    }
  }
  return '';
}

export function getPaymentDetails({ prices, usePaymentType, selectedPaymentType, paymentType }) {
  const paymentTypeValue = usePaymentType ? paymentType : selectedPaymentType;
  const loanPayment = get(prices, 'loan.payment', 0);
  const leasePayment = get(prices, 'estimateLeasePromise.monthlyPayment', 0);

  let paymentTitle = null;
  let paymentValue = null;

  if (loanPayment && paymentTypeValue === LOAN_PAYMENT) {
    paymentTitle = 'loan';
    paymentValue = formatPriceString(loanPayment);
  } else if (leasePayment && paymentTypeValue === LEASE_PAYMENT) {
    paymentTitle = 'lease';
    paymentValue = formatPriceString(leasePayment);
  }

  return {
    paymentTitle,
    paymentValue,
  };
}

export function getDisplayBelowMarketCopy({ paymentValue, isNew, dealerStateCode, savings }) {
  return !paymentValue && !isNew && !isTexas(dealerStateCode) && savings >= 200;
}

// do not show Trademark Character for Mercedes-Benz in SRP cards - BLT-1186
export function getVehicleName(vehicle, isEnhanced = false, isSrpExpanded = false) {
  const { vehicleInfo, type } = vehicle;
  const { make, model, year, trim = '', specialIdentifier = '' } = get(vehicleInfo, 'styleInfo', {});
  const styleInfo = get(vehicleInfo, 'styleInfo');
  const bodyType = get(styleInfo, 'bodyType');
  const isCpoType = isCpo(type);
  const cabType = getCabType(styleInfo, isSrpExpanded);

  let vehicleName = `${isCpoType ? 'Certified' : ''} ${year} ${make} ${model} ${trim}`.trim();
  const firstLine = `${isCpoType ? 'Certified' : ''} ${year} ${make} ${model}`.trim();
  let secondLine = `${trim}`.trim();

  if (isEnhanced) {
    const engineTypeValue = getEngineTypeValue({ model, trim, engineType: get(vehicleInfo, 'partsInfo.engineType') });
    vehicleName = [vehicleName, engineTypeValue].join(' ');
    secondLine = [secondLine, engineTypeValue].join(' ');
  }

  vehicleName = `${vehicleName} ${cabType}`.trim();
  secondLine = `${secondLine} ${cabType}`.trim();

  if (isSrpExpanded) return [firstLine, `${secondLine} ${specialIdentifier}`.trim()];

  if (isEnhanced && BODY_TYPES_ENHANCED.includes(bodyType) && !model.includes(bodyType) && !trim.includes(bodyType)) {
    vehicleName = `${vehicleName} ${bodyType}`;
  }

  return vehicleName;
}

export function getVdpLink({ vehicle, isNational, paymentType, radius, buyonline, deliveryFee }) {
  const skipLeasePaymentType = paymentType === LOAN_PAYMENT || !isLeasePaymentsEligible(vehicle);
  return getVdpUrl(vehicle, isNational, skipLeasePaymentType, { radius, buyonline, deliveryFee });
}

export function getInventoryBadgeText(vinInfo, showInTransit) {
  const { firstPublishedDate, prices, inTransit, type } = vinInfo;
  const { displayPriceDelta, displayPriceDeltaTimestamp } = prices;
  let text = null;
  if (showInTransit && inTransit && isNewType(type)) {
    return INVENTORY_BADGE_LABELS.ARRIVING_SOON;
  }

  if (getDifferenceInDays(new Date(firstPublishedDate)) < 7) {
    text = INVENTORY_BADGE_LABELS.NEW_LISTING;
  } else if (displayPriceDelta < 0 && getDifferenceInDays(new Date(displayPriceDeltaTimestamp)) < 7) {
    text = INVENTORY_BADGE_LABELS.PRICE_DROP;
  }

  return text;
}

export function getInventoryBadgeTextShop2809(vinInfo, showInTransit) {
  const { prices } = vinInfo;
  const { guaranteedPriceDelta, guaranteedPriceDeltaTimestamp } = prices;

  const text = getInventoryBadgeText(vinInfo, showInTransit);

  if (text && text !== INVENTORY_BADGE_LABELS.PRICE_DROP) {
    return text;
  }

  if (
    guaranteedPriceDeltaTimestamp &&
    guaranteedPriceDelta &&
    getDifferenceInDays(new Date(guaranteedPriceDeltaTimestamp)) < 7 &&
    guaranteedPriceDelta < -99
  ) {
    return INVENTORY_BADGE_LABELS.PRICE_DROP;
  }

  return null;
}

export function transformDerivedFeatures(vehicle) {
  return sortBy(get(vehicle, 'derivedGenericFeatures', []), ['displayPriority']).map(({ optionText }) => ({
    name: optionText,
  }));
}
